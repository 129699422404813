import React from "react"
import * as R from "ramda"
import PropTypes from "prop-types"
import Card from "../card"

const CollectionGrid = ({ children, tight }) => {
  if (tight) {
    return (
      <div
        className={`grid gap-8 grid-cols-1 md:grid-cols-2 md:gap-y-8 md:gap-x-5`}
      >
        {children}
      </div>
    )
  } else {
    return (
      <div
        className={`grid gap-8 grid-cols-1 md:grid-cols-3 md:gap-y-8 md:gap-x-5`}
      >
        {children}
      </div>
    )
  }
}

export default CollectionGrid

CollectionGrid.propTypes = {
  children: PropTypes.array.isRequired,
  tight: PropTypes.bool,
}
