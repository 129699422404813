import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby";
import * as R from "ramda"
import slugify from "slugify"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import BackLink from "../../components/backLink"
import CollectionHeader from "../../components/collectionHeader"
import CollectionGrid from "../../components/collectionGrid"
import Card from "../../components/card"
import Button from "../../components/button"
import Seo from "../../components/seo"
import LayoutController from "../../components/layoutController"

const ExpoCollectionPremises = ({ data }) => {
  const microCopyTexts = useMicroCopyFi
  const ctaLabel = GetMicroCopy(microCopyTexts, "yleinenAstuSisään")
  const content = R.path(["content"], data)
  const expoTitle = R.path(["title"], content)
  const virtualTitle = R.path(["virtualTitle"], content)
  const title = R.path(["premisesTitle"], content)
  const contentText = R.path(["premisesText", "premisesText"], content)
  const expoSlug = R.path(["slug"], content)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], content)
  let premises = R.path(["premises"], content)
  premises.forEach(item => {
    return (item.nr = parseInt(item.name.substring(0, 2)))
  })
  const sortByNameCaseInsensitive = R.sortBy(R.prop("nr"))
  premises = sortByNameCaseInsensitive(premises)
  const mainImageSEO = R.path(["mainImage", "file", "url"], content)
  const { shape_lightGreen } = data

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const language = R.path(["node_locale"], content)

  const naantali = expoSlug == 'naantali-2022' ? true : false

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={`${title} | ${expoTitle}`}
        seoDesc={contentText}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <section className="max-w-6xl mt-10 mb-20 px-5 md:mx-auto">
        <div className="mb-3">
          {naantali && (
            <div>
              <div className="mt-2">
              <BackLink  label={virtualTitle} url={`/${expoSlug}/virtuaalimessut/`} />
              </div>
            </div>
          )}
          {!naantali && (
            <BackLink label={virtualTitle} url={`/${expoSlug}/virtuaalimessut/`} />
          )}
        </div>
        <CollectionHeader title={title} text={contentText} image={mainImage} />
        {premises && (
          <div className="mt-12">
            <CollectionGrid>
              {premises.map(premise => {
                return (
                  <div className="relative">
                  { premise.isVirtual !== false ? (
                    <Card
                      image={R.path(["gatsbyImageData"], premise.mainImage)}
                      key={premise.name}
                      title={premise.name}
                      url={`${expoSlug}/${slugify(title, { lower: true })}/${
                        premise.slug
                      }`}
                      text={premise.contentTextShort}
                      language={language}
                    >
                      {naantali && (
                        <div className="inVirtual font-sans-medium absolute top-0 left-0 uppercase bg-lemonade text-[13px] tracking-wide p-1 px-2">Myös virtuaalissa</div> 
                      )}
                    </Card>
                  ) : (
                      <Card
                      image={R.path(["gatsbyImageData"], premise.mainImage)}
                      key={premise.name}
                      title={premise.name}
                      url={`${expoSlug}/${slugify(title, { lower: true })}/${
                        premise.slug
                      }`}
                      text={premise.contentTextShort}
                      language={language}
                      nolink
                    ></Card>
                  )}
                  </div>
                )
              })}
            </CollectionGrid>
          </div>
        )}
      </section>
    </LayoutController>
  )
}

export default ExpoCollectionPremises

export const pageQuery = graphql`
  query expoPremisesPage($parentSlug: String) {
    content: contentfulMessu(slug: { eq: $parentSlug }) {
      id
      slug
      node_locale
      title
      mainImage {
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      virtualTitle
      premisesTitle
      premisesText {
        premisesText
      }
      premises {
        ... on Node {
          ... on ContentfulKohdesivu {
            slug
            isVirtual
            name
            contentTextShort
            mainImage {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 80)
            }
          }
        }
      }
    }
  }
`
