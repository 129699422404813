import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Button = ({ url, children, onClick, secondary, externalUrl, backgroundColor, label }) => {
  const baseClasses = `inline-flex items-center uppercase px-4 py-1.5 text-sm text-black border border-solid hover:bg-black/10 hover:!text-black`
  const variants = secondary
    ? `border-[#bdd4e5] bg-white`
    : `bg-blue border-blue md:text-[17px]`
  const buttonClasses = `${baseClasses} ${variants}`
  let bgColor = ``

  if (backgroundColor) {
    if (backgroundColor[0] === "Vihreä") {
      bgColor = `bg-green border-green`
    }
    if (backgroundColor[0] === "Keltainen") {
      bgColor = `bg-yellow border-yellow`
    }
    if (backgroundColor[0] === "Sininen") {
      bgColor = `bg-blue border-blue`
    }
    if (backgroundColor[0] === "Pinkki") {
      bgColor = `bg-pink border-pink`
    }
    if (backgroundColor[0] === "Oranssi") {
      bgColor = `bg-orange border-orange`
    }
  }

  if (url) {
    return (
      <Link className={`${buttonClasses} ${bgColor}`} to={url}>
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button type="button" className={`${buttonClasses}`} onClick={onClick}>
        {children}
      </button>
    )
  } else if (externalUrl && label) {
    return (
      <a aria-label={label} className={`${buttonClasses}`} href={externalUrl}> 
        {children}
      </a>
    )
  } else if (externalUrl && !label) {
  return (
    <a className={`${buttonClasses}`} href={externalUrl}> 
      {children}
    </a>
    )
  } else {
    return (
      <span aria-hidden="true" className={`${buttonClasses}`}>
        {children}
      </span>
    )
  }
}

export default Button

Button.propTypes = {
  url: PropTypes.string,
  externalUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  backgroundColor: PropTypes.string,
  label: PropTypes.string
}
