import React from "react"
import GetPrefix from "../../helpers/GetPrefix"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const Card = ({ image, title, url, boxed, text, children, language, nolink }) => {
  return (
    <article className={`${boxed ? "bg-white" : ""} flex flex-col`}>
      <div className="relative">
        {image && (
          <div className="relative h-0 pb-[40%] md:pb-[56.25%] mb-4 ">
            <GatsbyImage
              className="w-full h-full !absolute top-0 left-0"
              image={image}
              alt=""
            />
          </div>
        )}
        <div className={`${boxed ? "px-5" : ""} `}>
          <h3 className="font-sans-medium mb-0">
          {!nolink && (
            <Link
              className="block text-text leading-none font-sans-medium hover:!text-text hover:!underline hover:decoration-1 underline-offset-4 after:content-[''] after:block after:absolute after:top-0 after:left-0 after:h-full after:w-full after:z-10"
              to={GetPrefix(language, url)}
            >
              {title}
            </Link>
            )}
            {nolink && (
              <span>{title}</span>
            )}
          </h3>
          {text && <p className="mt-5">{text}</p>}
        </div>
      </div>
      {children && <div className={`${boxed ? "px-5" : ""} `}> {children}</div>}
    </article>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  url: PropTypes.string,
  boxed: PropTypes.bool,
  children: PropTypes.node,
  text: PropTypes.string,
  language: PropTypes.string.isRequired,
  nolink: PropTypes.bool
}

export default Card
