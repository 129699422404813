import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Prose from "../prose"

const CollectionHeader = ({ title, image, text }) => {
  return (
    <header className="md:grid md:grid-cols-7 md:items-center gap-20">
      <div className="md:col-span-4 md:pt-8">
        <h1>{title}</h1>
        {text && <Prose content={text} />}
      </div>
      <GatsbyImage
        image={image}
        alt=""
        className="!hidden md:!block md:col-span-3 h-full"
      />
    </header>
  )
}

export default CollectionHeader

CollectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.object,
}
